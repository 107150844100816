<template>
    <div class="page-factory-quality-history">
        <div class="device-info" v-if="!isRuiy">
            <img v-if="info.deviceType == 2" :src="ossHost + 'img/icon_tlj.png'" alt="">
            <img v-if="info.deviceType > 2 && info.deviceType < 5" :src="ossHost + 'img/icon_ryy.png'" alt="">
            <img v-if="info.deviceType > 5 && info.deviceType < 30" :src="ossHost + 'img/ry_icon_v2.png'" alt="">
            <img v-if="info.deviceType >= 30" class="device-icon" :src="ossHost + 'img/icon-tl-box.jpg'" alt="">
            <span>{{ info.status | filterOrderStatus }}</span>
            <div class="device-num">{{ info.uniqueId }} <span v-if="deviceType && info.deviceType < 30">({{ deviceType }})</span></div>
            <div class="devive-name" v-if="deviceType && info.deviceType >= 30">{{ deviceType }}</div>
            <div class="device-batch">批次号：{{ info.productionBatch || '-' }}</div>
        </div>
        <div class="device-info device-kzq" v-else>
            <span>{{ info.status | filterOrderStatus }}</span>
            <div class="device-num">设备编号：{{ info.uniqueId }} <span v-if="deviceType">({{ deviceType }})</span></div>
            <!-- <div class="device-batch">批次号：{{info.productionBatch || '-'}}</div> -->
            <div class="device-batch" v-if="info.mainboardId">主板编号：{{ info.mainboardId || '-' }} <span
                    @click="updateBarCode(1)">修改</span></div>
            <div class="device-batch" v-else>主板编号： <span @click="updateBarCode(0)"><i class="el-icon-link"></i>关联</span>
            </div>
        </div>
        <div class="step-list">
            <div class="head">
                <div class="head-title">返厂记录</div>
                <div class="head-btn" @click="onclickShowRecord">更多</div>
            </div>
            <Steps direction="vertical">
                <Step v-if="info.logs">
                    <div>{{ info.logs[0].content }}</div>
                    <div class="step-time">{{ info.logs[0].time | getYMD }}</div>
                    <div class="edit-order-info" v-if="info.logs[0].type == 1 || info.logs[0].type == 2">
                        <span @click="viewOrderInfo(0, info.logs[0].type)">查看详情</span>
                        <span class="edit-order-btn" @click="editOrder" v-if="info.modifyTimes && info.logs[0].type == 1">修改</span>
                    </div>
                </Step>
            </Steps>
            <!-- <Steps direction="vertical">
                <Step v-for="item in info.logs" :key="item.time">
                    <div>{{ item.content }}</div>
                    <div class="step-time">{{ item.time | getYMD }}</div>
                    <div class="edit-order-info" v-if="item.type == 1 || item.type == 2">
                        <span @click="viewOrderInfo(0, item.type)">查看详情</span>
                        <span class="edit-order-btn" @click="editOrder" v-if="info.modifyTimes && item.type == 1">修改</span>
                    </div>
                </Step>
            </Steps> -->
        </div>

        <div class="step-list">
            <div class="head">退换信息</div>
            <div class="return-body">
                <!-- <div class="return-item">
                    <div class="item-label">创建类型</div>
                    <div class="item-text" v-if="info.createParty == 5">经销商退换</div>
                    <div class="item-text" v-else>{{ info.createParty == 2 ? '瑞银手动创建' : '工厂手动创建' }}</div>
                </div> -->
                <div class="return-item" v-if="info.firstBidnAt">
                    <div class="item-label">首次绑定日期</div>
                    <div class="item-text">{{ info.firstBidnAt | getYMD }}</div>
                </div>

                <div class="return-item" v-if="isRuiy">
                    <div class="item-label">使用年限</div>
                    <div :class="{ 'item-text': true, 'err-tag': info.useMonth >= 24 }">{{ parseInt(info.useMonth / 12)
                    }}年{{
    info.useMonth % 12 }}月</div>
                </div>

                <div class="return-item" v-if="info.ryFactoryType > 0 || info.deviceType>=30">
                    <div class="item-label">生产工厂</div>
                    <div class="item-text">{{ info.ryFactoryType == 1 ? '乐行' : '旺博' }}</div>
                </div>
                <div class="return-item" v-if="info.createParty == 5">
                    <div class="item-label">退换原因</div>
                    <div class="item-text">{{ info.tag || '暂无' }}</div>
                </div>
                <div class="return-item" v-if="info.createParty == 3">
                    <div class="item-label">故障类型</div>
                    <div class="item-text">{{ info.factoryTag || '暂无' }}</div>
                </div>
                <div class="return-item" v-if="info.createParty == 3">
                    <div class="item-label">故障描述</div>
                    <div class="item-text">{{ info.factoryReason || '暂无' }}</div>
                </div>
                <div class="return-item" v-if="info.createParty == 5">
                    <div class="item-label">退换时间</div>
                    <div class="item-text">{{ info.createAt | getYMD }}</div>
                </div>
                <div class="return-item item-mask" v-if="info.createParty == 5">
                    <div class="item-label">经销商备注</div>
                    <div class="item-text">{{ info.reason || '暂无' }}</div>
                </div>
                <div class="return-item">
                    <div class="item-label chart-label">离线前一个月告警</div>
                    <div class="item-text"></div>
                </div>
                <!-- <div id="ry-warning-chart" :style="{ width: canvasWith + 'px', minHeight: '250px' }"></div> -->
                <div id="warning-chart" :style="{ width: canvasWith + 'px'}"></div>
            </div>
        </div>

        <div class="btn" @click="viewOrderInfo(1)" v-if="info.status == 1 && !(!info.isInFactoryQcAt && isRuiy)">开始质检</div>
        <div class="btn" @click="onclickSubmitBtn" v-if="info.status == 1 && !info.isInFactoryQcAt && isRuiy">报废处理</div>

        <Popup v-model="isShowRecord" class="record-popup" :round="true" position="center"
            :close-on-click-overlay="false">
            <div class="step-list">
                <Steps direction="vertical">
                    <Step v-for="item in info.logs" :key="item.time">
                        <div>{{ item.content }}</div>
                        <div class="step-time">{{ item.time | getYMD }}</div>
                        <div class="edit-order-info" v-if="item.type == 1 || item.type == 2">
                            <span @click="viewOrderInfo(0, item.type)">查看详情</span>
                            <span class="edit-order-btn" @click="editOrder" v-if="info.modifyTimes && item.type == 1">修改</span>
                        </div>
                    </Step>
                </Steps>
            </div>
            <img class="record-popup-close-btn" :src="ossHostPond + 'img/icon_close.png'" @click="onclickShowRecord">
        </Popup>

        <Popup v-model="isShowPopup" :round="true" position="center" style="min-height: 20%"
            :close-on-click-overlay="false">
            <div class="pop-box">
                <div class="pop-header">关联主板编码</div>
                <div class="pop-body">
                    <Field v-model="barCode" label="" placeholder="请输入主板编号" input-align="left" right-icon="scan"
                        maxlength="13" type="string" @click-right-icon="onClickScanIcon" />
                </div>
                <div class="pop-footer">
                    <div class="footer-btn" @click="onclickClosePop(0)">取 消</div>
                    <div class="footer-btn sure-btn" v-if="barCode && barCode.length >= 10" @click="onclickClosePop(1)">确 定
                    </div>
                    <div class="footer-btn" v-else>确 定</div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Step, Steps, Toast, Popup, Field, Dialog } from 'vant'
import { filterOrderStatus, filterWarningTypeText } from '@/utils/str'
import { getYMD } from '@/utils/time'
export default {
    name: "RyQualityHistory",
    data() {
        const vm = this
        const { ossHost, ossHostPond } = vm.$config.base;
        const uuid = vm.$localStorage.getItem("uuid");
        const fType = vm.$strTool.filterFactoryType(uuid);
        return {
            ossHost,
            ossHostPond,
            info: {},
            canvasWith: window.screen.width - 60,
            isRuiy: fType == 'ruiyin',
            barCode: '',
            isShowPopup: false,
            isShowRecord: false,
            deviceType: ''
        };
    },
    components: {
        Steps,
        Step,
        Popup,
        Field
    },
    filters: {
        filterOrderStatus,
        getYMD
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')

        const fType = vm.$strTool.filterFactoryType(uuid);
        vm.fType = fType;
        if (!name) {
            vm.$router.replace({
                path: "/factory/login"
            });
            return;
        }

        if (vm.$config.base.fList.indexOf(fType) == -1) {
            vm.$router.replace({
                path: "/factory/login"
            });
            return
        }
        vm.loadDetail();
    },
    methods: {
        loadDetail() {
            const vm = this
            const params = {}
            let url = `${vm.$config.base.PROBE_BASE_URL}returnFac/${vm.$route.query.id}/detail`
            vm.ttid = Toast.loading({  
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            if (vm.fType != 'ruiyin') {
                params.uniqueId = vm.$route.params.id
                url = `${vm.$config.base.PROBE_BASE_URL}returnFac/detail`
            }

            vm.$http({
                type: "get",
                url,
                params
            }).then(res => {
                if (res.data) {
                    res.data.flow && res.data.flow.length && res.data.flow.reverse()
                    vm.info = res.data;
                    vm.barCode = res.data.mainboardId;
                    vm.deviceType = window.deviceTypes.filter(item => item.type == res.data.deviceType)[0].alias
                    console.log(vm.deviceType)
                    vm.loadWarningList()
                } else {
                    Toast.fail({
                        message: '设备编码不存在~',
                        forbidClick: true,
                        overlay: true,
                        duration: 2000,
                    });
                }
                vm.ttid.clear()
            }).catch(err => {
                console.log(err)
                vm.ttid.clear()
            });
        },
        viewOrderInfo(type, showType) {
            this.$router.push({
                path: `/factory/quality/info/${this.$route.params.id}?type=${type}&showType=${showType}`
            });
        },
        loadWarningList() {
            const vm = this
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}monthWarning`,
                params: { uniqueId: vm.$route.params.id }
            }).then(res => {
                const element = document.getElementById('warning-chart');
                // 设置图表容器高度
                element.setAttribute('style', `height:${res.data.length * 50}px`);  
                element && element.setAttribute('_echarts_instance_', '');
                try {
                    const myChart = vm.$echarts.init(document.getElementById("warning-chart"));
                    const xData = [];
                    const yData = [];
                    const colors = ['#E00906', '#FF9E20', '#FFD700', '#F5EA14'];
                    if (res.data) {
                        res.data.sort((a, b) => {return a.size - b.size})
                        res.data.map(item => {
                            xData.push(filterWarningTypeText(item.warningType))
                            yData.push(item.size)
                        })
                    }
                    myChart.setOption({
                        height: res.data.length * 50 + 'px',
                        xAxis: {
                            type: 'value',
                            show: false
                        },
                        yAxis: [
                            {
                                data: xData,
                                axisLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    verticalAlign: 'bottom',
                                    align: 'left',
                                    padding: [0, 0, 10, 10],
                                    textStyle: {
                                        color: '#000',
                                        fontSize: '16',
                                    },
                                },
                            },
                            {
                                axisLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                data: yData,
                                axisLabel: {
                                    show: true,
                                    verticalAlign: 'bottom',
                                    align: 'right',
                                    padding: [0, 10, 10, 0],
                                    textStyle: {
                                        color: '#000',
                                        fontSize: '16',
                                    }
                                },
                            }
                        ],
                        series: [
                            {
                                name: '数量',
                                type: 'bar',
                                barWidth: 18,
                                data: yData,
                                barCategoryGap: 0,
                                itemStyle: {
                                    color: function (params) {
                                        const index = (res.data.length - 1) - params.dataIndex;
                                        return colors[index] || '#F5EA14';
                                    }
                                }
                            }
                        ],
                        grid: {
                            top: '10',
                            left: '0',
                            right: '6'
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
                console.log(res)
            }).catch(err => {
                console.log(err)
            });
        },

        onclickClosePop(type) {
            const vm = this
            // 取消关联
            if (!type) {
                vm.barCode = '';
                vm.isShowPopup = false;
                return
            }

            // 确定关联
            vm.tt = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            vm.$http({
                type: "put",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/mainboardId`,
                data: {
                    password: vm.$config.base.password,
                    // operator: vm.name,
                    mainboardId: vm.barCode,
                    id: vm.info.id
                },
                except: true,
            }).then(res => {
                const { code, message } = res
                vm.tt.clear();
                if (code == 1) {
                    Toast.success({
                        message: vm.info.mainboardId ? '修改成功' : '关联成功',
                        forbidClick: true,
                        overlay: true,
                        duration: 2000
                    });
                    vm.isShowPopup = false;
                    setTimeout(vm.loadDetail, 2000);
                } else {
                    Toast.fail({
                        message,
                        forbidClick: true,
                        overlay: true,
                        duration: 2000
                    });
                }
            }).catch(() => {
                vm.tt.clear();
            })
        },

        // type: 1修改关联，0无关联的关联
        updateBarCode(type) {
            this.barCode = !type ? '' : this.info.mainboardId
            this.isShowPopup = true
        },

        onClickScanIcon() {
            const vm = this;
            window.wx.scanQRCode({
                needResult: 1,
                scanType: ["barCode"],
                success: function (res) {
                    if (!(res.resultStr && res.resultStr.length >= 10)) {
                        Dialog.confirm({
                            title: "提示",
                            message: "非设备主板码~",
                            confirmButtonColor: "#32BE32",
                            showCancelButton: false
                        });
                    } else {
                        vm.barCode = res.resultStr.split(',').length > 1 ? res.resultStr.split(',')[1] : res.resultStr
                        // vm.barCode = res.resultStr
                    }
                }
            });
        },

        editOrder() {
            const vm = this
            vm.$router.push({
                path: `/factory/quality/info/${vm.$route.params.id}?id=${vm.info.id}&ed=1&type=1`
            });
        },

        // 超年限报废处理
        onclickSubmitBtn() {
            const vm = this
            const { USER_NAME } = vm.$config.keys;
            const operator = vm.$localStorage.getItem(USER_NAME);
            vm.tt = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            vm.$http({
                method: "put",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac`,
                data: {
                    handlingMethod: 4,
                    operator,
                    password: vm.$config.base.password,
                    id: vm.info.id,
                    responsibleParty: 5
                }
            }).then(res => {
                vm.tt.clear();
                if (res.code != 1) {
                    Toast.fail({
                        message: res.message,
                        forbidClick: true,
                        overlay: true,
                        duration: 2000
                    });
                    return
                }
                Toast.success({
                    message: '已进行报废处理',
                    forbidClick: true,
                    overlay: true,
                    duration: 2000
                })
                setTimeout(() => {
                    vm.$router.replace({
                        path: `/factory/quality/qualityList`
                    });
                }, 2000);
            }).catch(err => {
                console.log(err);
                vm.tt.clear();
            });
        },

        // 查看返厂记录
        onclickShowRecord() {
            const vm = this;
            vm.isShowRecord = !vm.isShowRecord;
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";

.page-factory-quality-history {
    width: 100%;
    height: 100%;
    background: @bgColor2;
    position: relative;
    box-sizing: border-box;
    padding: 20px 10px;
    font-size: 0.16rem;
    padding-bottom: 100px;
    overflow-x: hidden;

    .device-info {
        background: @blank;
        position: relative;
        padding: 20px 10px;
        padding-left: 100px;
        margin-bottom: 20px;
        box-shadow: 0px 2px 10px 0px #E5E5E5;

        &.device-kzq {
            padding-left: 10px;
            font-size: 0.16rem;
            color: @textColor4;

            .device-num {
                font-size: 0.16rem;
            }
        }

        img {
            position: absolute;
            width: 80px;
            left: 10px;
            top: 8px;
        }

        .device-icon {
            margin-top: 0.2rem;
        }

        .device-num {
            font-size: 0.2rem;
            color: @textColor4;
            padding-bottom: 0.1rem;
            span {
                font-size: 0.1rem;
                position: static;
                color: @textColor4;
                font-weight: normal;
            }
        }

        .devive-name {
            padding-bottom: 0.1rem;
        }

        .device-batch {
            color: @black;

            span {
                position: static;
                display: inline-block;
                padding-left: 5px;
                color: @themeColor;

                i {
                    font-size: 0.2rem;
                    vertical-align: middle;
                }
            }
        }

        span {
            position: absolute;
            right: 10px;
            top: 20px;
            color: #FB3D66;
            font-weight: bold;
        }
    }

    .step-list {
        background: @blank;
        position: relative;
        padding: 20px;
        box-shadow: 0px 2px 10px 0px #E5E5E5;
        margin-bottom: 10px;
        z-index: 1;

        .head {
            font-size: 0.18rem;
            color: @textColor4;
            padding-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .head-btn {
                color: @themeColor;
            }
        }

        .van-steps {
            position: relative;
            padding-left: 100px;
            min-height: 60px;
            color: @textColor5;

            .van-step__title {
                color: @textColor5;
                line-height: 1.2;
            }

            .step-time {
                position: absolute;
                font-size: 0.12rem;
                color: @textColor5;
                left: -100px;
                top: 10px;
                width: 50px;
            }

            .van-step {
                font-size: 0.16rem;
            }

            .edit-order-info {
                font-size: 0.12rem;
                color: @textColor6;
                padding: 10px 0;

                .edit-order-btn {
                    margin-left: 10px;
                    color: red;
                }
            }
        }

        .return-body {
            font-size: 0.15rem;

            .return-item {
                display: flex;
                justify-content: space-between;
                padding-bottom: 15px;
                color: @textColor1;

                &.item-mask {
                    display: block;

                    .item-text {
                        margin-top: 5px;
                        padding: 5px;
                        background: #eee;
                    }
                }

                .item-label {
                    width: 100px;

                    &.chart-label {
                        width: 100%;
                    }
                }

                .item-text {
                    color: @textColor4;

                    &.err-tag {
                        color: red;
                    }
                }
            }
        }
    }

    .btn {
        position: fixed;
        width: 100%;
        line-height: 0.5rem;
        text-align: center;
        color: @blank;
        font-size: 0.2rem;
        // border-radius: 0.25rem;
        box-shadow: 0px 2px 10px 0px @textColor3;
        background: @textColor3;
        bottom: 0;
        left: 0;
        z-index: 10;
    }

    .van-popup {
        width: 80%;

        .pop-box {
            padding: 20px;
            text-align: center;
            font-size: 0.2rem;

            .pop-header {
                padding-bottom: 30px;
            }

            .pop-body {
                border-bottom: 1px solid @textColor1;

                .van-field {
                    padding-left: 0;
                    padding-right: 0;

                    input {
                        font-size: 0.2rem;
                        text-align: center;
                    }

                    i {
                        font-size: 0.26rem;
                        color: @themeColor;
                    }
                }
            }

            .pop-footer {
                display: flex;
                justify-content: space-between;
                padding-top: 20px;

                .footer-btn {
                    width: 110px;
                    line-height: 40px;
                    border: 1px solid @textColor1;
                    color: @textColor1;
                    border-radius: 20px;

                    &.sure-btn {
                        color: @themeColor;
                        border-color: @themeColor;
                    }
                }
            }
        }
    }

    .record-popup {
        width: 90%;
        height: 400px;
        top: 40%;
        overflow: visible;
        padding: 20px 0;
        .step-list {
            box-shadow: none
        }
        .record-popup-close-btn {
            height: 50px;
            width: 50px;
            position: absolute;
            bottom: -60px;
            left: 50%;
            transform: translate(-50%,0);
        }
    }
}
</style>
